import React from 'react';
import Tag from '../Tag';
import './filterTHead.scss';

const FilterTHead = ({ data, handleSortURL }) => {

  return (
    <ul className="container__filter--thead t--MINI--content">
      {data.map((el, i) =>
        <li
          key={i}
          className="filter--thead"
          onClick={() => handleSortURL(el.name)}
        >
          <Tag data={el}/>
        </li>
      )}
    </ul>
  );
};

export default FilterTHead;